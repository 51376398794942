import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LastUpdated from "../components/LastUpdated/LastUpdated"
import CopyBlock from "../components/Copy/CopyBlock"

// import '../css/blog-post.css';

export default function Article({ data }) {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <SEO
        title={`${post.frontmatter.title}`}
        canonicalPath={`/articles/${post.frontmatter.slug}/`}
        description={`${post.frontmatter.desc ? post.frontmatter.desc : post.excerpt}`}
      />

      <article className="w-full mx-auto max-w-screen-xl mt-4 2xl:mt-8">

        <div className="blog-post bg-noir-lighten5 rounded-md">

          <div className="w-full mx-auto max-w-screen-md p-6 md:p-10">

            <h1 className="text-left w-full max-w-none text-2xl md:text-4xl md:leading-snug font-bold text-white tracking-normal">{post.frontmatter.title}</h1>

            <p className="uppercase text-xs tracking-wider opacity-50 my-3 xl:my-4 pb-2">{post.frontmatter.date}</p>

            <div
              className="blog-post-content border-t border-white border-opacity-20 pt-10 mt-4 text-gray-300 leading-relaxed"
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </div>

        </div>

      </article>

      <LastUpdated type="Stats" />
    </Layout>
  )
}

export const query = graphql`
  query ArticleQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
        html
        frontmatter {
          slug
          date(formatString: "MMMM DD, YYYY")
          title
          desc
        }
        excerpt
      }
  }
`
